import { md5Data, axiosGet, axiosPost } from "@api/request"
import apis from "@api/apis"

function MP(ak) {
    return new Promise(function(resolve, reject) {
        window.onload = function() {
            resolve(BMap)
        }
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "http://api.map.baidu.com/api?v=2.0&ak=" + ak + "&callback=init";
        script.onerror = reject;
        document.head.appendChild(script);
    })
}

function uploadImg(file, type, progressFn = false) {
    return new Promise((resolve, reject) => {
        let config = {
            'Content-Type': 'multipart/form-data'
        }
        let formData = new FormData()
        let md5sign = md5Data({ t: Date.parse(new Date()) });
        formData.append('file', file)
        formData.append('t', Date.parse(new Date()))
        formData.append('sign', md5sign)
        let url = '';
        if (progressFn) {
            config.onUploadProgress = progressFn;
        }
        if (type != '' && type.indexOf('image') > -1) {
            url = apis.uploadImage;
        } else {
            url = apis.uploadFile;
        }
        axiosPost(url, formData, config).then(datas => {
            resolve(datas)
        }).catch(err => {
            reject(err)
        })
    })
}
export {
    MP,
    uploadImg
}