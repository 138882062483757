<template>
  <div class="map_container">
    <div id="mapContainer"></div>
    <div class="order_content_wrap">
      <div class="content_top">
        <div class="flex_between_center content_header">
          <span class="content_title" style="color: #f54123">{{
            orderData && orderData.back_car == 0 ? "专车" : "回程车"
          }}</span>
          <div class="content_tag" style="color: #02a7f0">
            {{
              orderData && orderData.order_info.trip_type == 1
                ? "实时订单"
                : "预约订单"
            }}
          </div>
        </div>
        <div v-if="orderData && orderData.order_status != 51">
          <div
            class="order_driver"
            v-if="orderData && orderData.order_status == 11"
          >
            <div class="flex_between_center">
              <div class="flex_start_center">
                <span class="driver_name" style="color: #999"
                  >司机匹配中,请耐心等待</span
                >
              </div>
            </div>
          </div>
          <div
            class="order_driver"
            v-else-if="orderData && orderData.order_status == 50"
          >
            <div class="flex_between_center">
              <div class="flex_start_center">
                <span class="driver_name" style="color: #999"
                  >用户已取消订单</span
                >
              </div>
            </div>
          </div>
          <div class="order_driver" v-else>
            <img src="@assets/imgs/u823.png" class="sanjiao" alt="" />
            <div class="flex_between_center">
              <div class="flex_start_center">
                <img
                  :src="
                    orderData && orderData.motorman_info.avatar
                      ? orderData.motorman_info.avatar
                      : '@assets/imgs/156.jpg'
                  "
                  class="driver_avatar"
                  alt=""
                />
                <span class="driver_name">{{
                  orderData && orderData.motorman_info.real_name
                }}</span>
              </div>
              <el-rate
                v-model="rate_val"
                disabled
                show-score
                text-color="#f54123"
                allow-half
                :colors="colors"
                score-template="{value}"
              ></el-rate>
            </div>
            <div class="driver_phone">18520987432</div>
            <div class="driver_status">
              {{ orderData && orderData.motorman_info.car_number }} ({{
                orderData && orderData.motorman_info.car_type_name
              }}})
              <span v-if="orderData && orderData.order_info.order_status == 20"
                >已接单</span
              >
              <span
                v-else-if="orderData && orderData.order_info.order_status == 30"
                >运输中</span
              >
              <span
                v-else-if="orderData && orderData.order_info.order_status == 31"
                >待签收</span
              >
              <span
                v-else-if="orderData && orderData.order_info.order_status == 40"
                >已完成</span
              >
              <span
                v-else-if="orderData && orderData.order_info.order_status == 51"
                >已取消</span
              >
            </div>
          </div>
        </div>
        <div class="flex_between_center content_header">
          <span class="content_title" style="color: #f54123"
            >￥{{ orderData && orderData.estimate_amount }}</span
          >
          <div class="content_tag" style="color: #f54123">价格明细</div>
        </div>
        <div class="txt_font" style="color: #f54123; margin-bottom: 10px">
          到达时间：要求
          {{ orderData && orderData.order_info.user_assign_time }}到达
        </div>
        <el-steps direction="vertical" :active="1">
          <el-step
            class="step_item start_step_item"
            v-for="(item, index) in orderData && orderData.haulwayList"
            :key="index"
            v-if="item.action == 1"
          >
            <template #icon>
              <div class="step_icon">装</div>
            </template>
            <template #description>
              <div class="step_description flex_between_center">
                <div class="column_start">
                  <span class="txt_font">{{ item.address_name }}</span>
                  <span
                    class="txt_font"
                    style="color: #aaa"
                    v-if="item.linkman_name"
                    >{{ item.linkman_name }}</span
                  >
                  <span
                    class="txt_font"
                    style="color: #aaa"
                    v-if="item.linkman_phone"
                    >{{ item.linkman_phone }}</span
                  >
                </div>
                <div class="txt_font" style="color: #aaa">
                  装货<span style="color: #299ede">{{
                    item.real_cargo_num || item.cargo_num
                  }}</span
                  >件
                </div>
              </div>
            </template>
          </el-step>
          <el-step
            class="step_item last_step_item"
            v-for="(item, index) in orderData && orderData.haulwayList"
            :key="index"
            v-if="item.action != 1"
          >
            <template #icon>
              <div class="step_icon" style="background: #ff6543">卸</div>
            </template>
            <template #description>
              <div class="step_description flex_between_center">
                <div class="column_start">
                  <span class="txt_font">{{ item.address_name }}</span>
                  <span
                    class="txt_font"
                    style="color: #aaa"
                    v-if="item.linkman_name"
                    >{{ item.linkman_name }}</span
                  >
                  <span
                    class="txt_font"
                    style="color: #aaa"
                    v-if="item.linkman_phone"
                    >{{ item.linkman_phone }}</span
                  >
                </div>
                <div class="txt_font" style="color: #aaa">
                  卸货<span style="color: #f54123">{{
                    item.real_cargo_num || item.cargo_num
                  }}</span
                  >件
                </div>
              </div>
            </template>
          </el-step>
        </el-steps>
      </div>
      <div style="width: 100%; height: 10px; background: #f7f8fa"></div>
      <div
        class="content_bottom"
        v-if="
          orderData &&
          orderData.order_info.order_status >= 20 &&
          orderData.order_info.order_status != 51
        "
      >
        <div class="flex_start_center content_header">
          <span class="content_title" style="color: #333">订单动态</span>
        </div>
        <el-steps
          direction="vertical"
          v-if="orderData && orderData.order_info.order_status >= 50"
        >
          <el-step class="step_item last_step_item">
            <template #icon>
              <div class="step_icon"></div>
            </template>
            <template #description>
              <div class="step_description flex_between_center">
                <div class="column_start">
                  <span
                    class="txt_font"
                    v-if="orderData && orderData.order_info.order_status == 50"
                    >用户已取消订单</span
                  >
                  <span
                    class="txt_font"
                    v-else-if="
                      orderData && orderData.order_info.order_status == 51
                    "
                    >司机已取消订单</span
                  >
                  <span class="txt_font" v-else>平台已取消订单</span>
                  <span class="txt_font" style="color: #aaa">{{
                    orderData &&
                    orderData.order_info.cancel_time | timestampToTime
                  }}</span>
                </div>
              </div>
            </template>
          </el-step>
        </el-steps>
        <el-steps direction="vertical" :active="1" v-else>
          <!-- <el-step class="step_item start_step_item">
                        <template #icon>
                            <div class="step_icon" style="background:#02a7f0;"></div>
                        </template>
                        <template #description>
                            <div class="step_description flex_between_center">
                                <div class="column_start">
                                    <span class="txt_font">司机已发车运输中,前往途经点: XX地址</span>
                                    <span class="txt_font" style="color:#aaa;">2019-12-09 16:23:23</span>
                                </div>
                            </div>
                        </template>
                    </el-step> -->
          <el-step
            class="step_item last_step_item"
            v-if="orderData && orderData.order_info.order_status >= 41"
          >
            <template #icon>
              <div class="step_icon"></div>
            </template>
            <template #description>
              <div class="step_description flex_between_center">
                <div class="column_start">
                  <span class="txt_font">客户已支付,订单完成</span>
                  <span class="txt_font" style="color: #aaa">{{
                    (orderData && orderData.order_info.pay_time) ||
                    (orderData && orderData.order_info.end_time)
                      | timestampToTime
                  }}</span>
                </div>
              </div>
            </template>
          </el-step>
          <el-step
            class="step_item last_step_item"
            v-if="orderData && orderData.order_info.order_status >= 40"
          >
            <template #icon>
              <div class="step_icon"></div>
            </template>
            <template #description>
              <div class="step_description flex_between_center">
                <div class="column_start">
                  <span class="txt_font">订单已签收,准备结算</span>
                  <span class="txt_font" style="color: #aaa">{{
                    orderData &&
                    orderData.order_info.sign_time | timestampToTime
                  }}</span>
                </div>
              </div>
            </template>
          </el-step>
          <el-step
            class="step_item start_step_item"
            v-for="(item, index) in orderData && orderData.haulwayList"
            :key="index"
            v-if="
              orderData &&
              orderData.order_info.order_status >= 30 &&
              item.real_cargo_num &&
              item.motorman_in_time
            "
          >
            <template #icon>
              <div class="step_icon"></div>
            </template>
            <template #description>
              <div class="step_description flex_between_center">
                <div class="column_start">
                  <span class="txt_font" v-if="item.action == 1"
                    >司机已到达{{ item.address_name }},装车完成,开始运输(装车:{{
                      item.real_cargo_num
                    }}件)</span
                  >
                  <span class="txt_font" v-else
                    >司机已到达{{ item.address_name }},卸货完成(卸货:{{
                      item.real_cargo_num
                    }}件)</span
                  >
                  <span class="txt_font" style="color: #aaa">{{
                    item.motorman_in_time | timestampToTime
                  }}</span>
                </div>
              </div>
            </template>
          </el-step>
          <el-step
            class="step_item last_step_item"
            v-if="orderData && orderData.order_info.order_status >= 22"
          >
            <template #icon>
              <div class="step_icon"></div>
            </template>
            <template #description>
              <div class="step_description flex_between_center">
                <div class="column_start">
                  <span class="txt_font">司机已到达指定地点,等待装车</span>
                  <span class="txt_font" style="color: #aaa">{{
                    orderData &&
                    orderData.order_info.reach_address_time | timestampToTime
                  }}</span>
                </div>
              </div>
            </template>
          </el-step>
          <el-step
            class="step_item last_step_item"
            v-if="orderData && orderData.order_info.order_status >= 20"
          >
            <template #icon>
              <div class="step_icon"></div>
            </template>
            <template #description>
              <div class="step_description flex_between_center">
                <div class="column_start">
                  <span class="txt_font">司机已接单</span>
                  <span class="txt_font" style="color: #aaa">{{
                    orderData &&
                    orderData.order_info.receiving_time | timestampToTime
                  }}</span>
                </div>
              </div>
            </template>
          </el-step>
        </el-steps>
      </div>
    </div>
  </div>
</template>

<script>
var map;
import { MP } from "@/utils/util.js";
import { handleGetOrderDetail } from "@api/checkOrder";
// import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
// import BmGeolocation from 'vue-baidu-map/components/controls/Geolocation.vue'; //定位
// import BmMapType from 'vue-baidu-map/components/controls/MapType.vue'; //地图类型
// import BmCityList from 'vue-baidu-map/components/controls/CityList.vue'; //城市列表
// import BmNavigation from 'vue-baidu-map/components/controls/Navigation.vue'; //缩放
// import BmDriving from 'vue-baidu-map/components/search/Driving.vue'; //路线
// import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'; //标记 点
// import BmView from 'vue-baidu-map/components/map/MapView';

import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  components: {
    // BaiduMap,
    // BmGeolocation,
    // BmMapType,
    // BmCityList,
    // BmMarker,
    // BmNavigation,
    // BmDriving,
    // BmView
  },
  data() {
    return {
      order_sn: "",
      orderData: null,
      load_num: 0,
      unload_num: 0,
      center: { lng: 0, lat: 0 },
      zoom: 3,
      colors: ["#99A9BF", "#F7BA2A", "#f54123"],
      rate_val: 4.9,
      marker_icon: {
        url: require("@assets/imgs/location_icon.png"),
        size: { width: 32, height: 32 },
      },
      // waypoints:['呼和浩特', {lng: 112.53, lat: 37.87}, '陕西兵马俑'],        //途经点
      waypoints: [], //地图所有点
      wayPoiObj: {},
      my_map: null, //地图实例
      from: "",
      to: "",
      gaoDeMap: null, //高德地图对象
    };
  },
  created() {
    let { order_sn = false } = this.$route.params;
    if (order_sn) {
      this.order_sn = order_sn;
      this.getOrder();
    }
  },
  mounted() {
    this.initGaoDeMap();
  },
  computed: {
    userData: function () {
      return this.$store.getters.userData || this.$storage.get("userData");
    },
  },
  methods: {
    // 初始化百度地图
    initBaiDuMap() {
      map = new BMap.Map("mapContainer");
      map.centerAndZoom(new BMap.Point(112.89996, 28.198), 13);
      map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
      map.addControl(new BMap.NavigationControl()); // 添加平移缩放控件
      map.addControl(new BMap.ScaleControl()); // 添加比例尺控件
      map.addControl(new BMap.OverviewMapControl()); // 添加缩略地图控件
      var localSearch = new BMap.LocalSearch(map);
      localSearch.enableAutoViewport(); //允许自动调节窗体大小
    },
    initGaoDeMap() {
      window._AMapSecurityConfig = {
        securityJsCode: "c02c97d41bdd0909efdcaa844373029a",
      };
      AMapLoader.load({
        key: "b64f93db8860c7599fe1a88f8ad4296b", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JS API 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.ToolBar",
          "AMap.Scale",
          "AMap.ControlBar",
          "AMap.HawkEye",
          "AMap.Geolocation",
          "AMap.PlaceSearch",
          "AMap.AutoComplete",
          "AMap.Icon",
          "AMap.Pixel",
          "AMap.Geocoder",
          "AMap.InfoWindow",
          "AMap.Driving",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        map = new AMap.Map("mapContainer", {
          resizeEnable: true,
          clickable: true,
          zoom: 18, //初始化地图层级
        });
        this.gaoDeMap = map;

        const scale = new AMap.Scale({
          visible: true,
        });
        const controlBar = new AMap.ControlBar({
          visible: true,
          position: {
            top: "10px",
            left: "10px",
          },
        });
        const toolBar = new AMap.ToolBar({
          visible: true,
          position: {
            top: "110px",
            left: "40px",
          },
        });
        map.addControl(scale);
        map.addControl(controlBar);
        map.addControl(toolBar);
      });
    },
    searchByStationName1() {
      var _this = this;
      var startAddrr = this.orderData.haulwayList
        .filter((i) => i.action == 1)
        .map((item) => item.address_info); //获得起点地名
      var localSearch = new BMap.LocalSearch(map);

      console.log("searchByStationName1", startAddrr, localSearch);
      if (_this.orderData.haulwayList.length > 0) {
        let _waypoints = _this.orderData.haulwayList.map((i) => {
          return i.address_info;
        });
        console.log(_waypoints);
        _waypoints.forEach((item, index) => {
          _this.SearchLatLng(item, index);
        });
      }
    },
    SearchLatLng(item, index) {
      var _this = this;
      var localSearch = new BMap.LocalSearch(map);
      localSearch.search(item);
      localSearch.setSearchCompleteCallback(function (searchResult) {
        var poi = searchResult.getPoi(0);
        if (poi != null) {
          //判断地名是否存在
          _this.waypoints.push(poi.point);
          _this.$set(_this.wayPoiObj, `${index}`, poi.point);
          console.log("执行_index", index);
          if (index == _this.orderData.haulwayList.length - 1) {
            _this.run();
          }
        } else {
          alert("请输入正确的地名！");
        }
      });
    },
    run() {
      let that = this;
      map.clearOverlays(); // 清除地图上所有的覆盖物
      var driving = new BMap.DrivingRoute(map); // 创建驾车实例
      var waypoints = this.waypoints;

      if (waypoints.length > 2) {
        for (let i in that.wayPoiObj) {
          let plus = Number(i) + 1;
          if (i <= waypoints.length - 2) {
            console.log("_this.wayPoiObj.", that.wayPoiObj[i]);
            console.log("_this.wayPoiObj.", that.wayPoiObj[plus]);
            driving.search(that.wayPoiObj[i], that.wayPoiObj[plus]);
          }
        }
      } else {
        driving.search(that.wayPoiObj[0], that.wayPoiObj[1]);
      }

      driving.setSearchCompleteCallback(function () {
        var pts = driving.getResults().getPlan(0).getRoute(0).getPath(); // 通过驾车实例，获得一系列点的数组

        console.log("completeCallback start!", pts);
        var polyline = new BMap.Polyline(pts);
        map.addOverlay(polyline);
        if (that.waypoints.length > 2) {
          that.waypoints.forEach((item, index) => {
            if (index != 0 || index != that.waypoints.length - 1) {
              let findex = new BMap.Marker(item);
              map.addOverlay(findex);
              if (index == 0) {
                var labtext = new BMap.Label("起点", { position: item });
                map.addOverlay(labtext);
              } else if (index == that.waypoints.length - 1) {
                var labtext = new BMap.Label("终点", { position: item });
                map.addOverlay(labtext);
              } else {
                var labtext = new BMap.Label("途经点", { position: item });
                map.addOverlay(labtext);
              }
            }
          });
        } else {
          var labtext1 = new BMap.Label("起点", {
            position: that.waypoints[0],
          });
          var labtext2 = new BMap.Label("终点", {
            position: that.waypoints[1],
          });
          map.addOverlay(labtext1);
          map.addOverlay(labtext2);
          var m1 = new BMap.Marker(that.waypoints[0]);
          var m2 = new BMap.Marker(that.waypoints[1]);
          map.addOverlay(m1);
          map.addOverlay(m2);
        }
        setTimeout(function () {
          map.setViewport(that.waypoints); // 调整到最佳视野
        }, 1000);
      });
    },
    getOrder() {
      let datakey = {};
      datakey["user_sn"] = this.userData.user_sn;
      datakey["order_sn"] = this.order_sn;
      handleGetOrderDetail(datakey)
        .then((datas) => {
          if (datas.status == 0) {
            this.getCargoNum(datas.data);
            this.orderData = datas.data;
            // this.searchByStationName1();
            // 绘制高德地图汽车导航线路
            this.drawMapPolylineWithGaoDe();
          } else {
            this.$message.error(err.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    drawMapPolylineWithGaoDe() {
      let haulwayList = this.orderData.haulwayList || [];
      let driving = new AMap.Driving({
        map: this.gaoDeMap,
        showTraffic: true,
        autoFitView: true,
        hideMarkers: true,
        ferry: 1,
        extensions: "all",
        // policy: AMap.DrivingPolicy.LEAST_TIME
      });
      //   规划参数
      let params = {
        start: [],
        end: [],
        road: [],
      };
      for (let i = 0; i < haulwayList.length; i++) {
        const item = haulwayList[i];
        if (i == 0) {
          params.start = [Number(item.lng), Number(item.lat)];
        } else if (i == haulwayList.length - 1) {
          params.end = [Number(item.lng), Number(item.lat)];
        } else {
          params.road.push([Number(item.lng), Number(item.lat)]);
        }

        if (item.lng && item.lat) {
          // 创建纯文本标记
          const text = new AMap.Text({
            text: item.action == 1 ? "装" : "卸",
            anchor: "center", // 设置文本标记锚点
            draggable: true,
            cursor: "pointer",
            angle: 10,
            style: {
              "border-radius": "50%",
              "background-color": item.action == 1 ? "#02a7f0" : "#ff6543",
              width: "2rem",
              height: "2rem",
              "line-height": "2rem",
              "border-width": 0,
              "text-align": "center",
              "font-size": "12px",
              color: "white",
            },
            position: [Number(item.lng), Number(item.lat)],
            title: item.address_name,
            zIndex: i * 10,
          });

          text.setMap(this.gaoDeMap);

          //   const infoWindow = new AMap.InfoWindow({
          //     content: `<div>${item.address_name}</div>`, //使用默认信息窗体框样式，显示信息内容
          //   });
          //   infoWindow.open(this.gaoDeMap, [item.lng, item.lat]);
        }
      }

      console.log(params);

      // 根据起终点名称规划驾车导航路线
      driving.search(
        params.start,
        params.end,
        {
          waypoints: params.road,
        },
        (status, result) => {
          // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
          if (status === "complete") {
            console.log("绘制驾车路线完成", status, result);
            // let polylineList = [];
            // for (const item of result.routes) {
            //      const path =[]
            //     for (const e of item.steps) {
            //         console.log(e.path);
            //         for (const k of e.path) {
            //         path.push([k.lng,k.lat])
                        
            //         }
            //     }
             
            //   console.log("path", path);
            //   const polyline = new AMap.Polyline({
            //     path,
            //     strokeColor: "#07c060",
            //     strokeWeight : 9,
            //     strokeOpacity: 0.9,
            //     zIndex: 50,
            //     showDir : true,
            //     geodesic  : true,
            //     bubble: true,
            //   });
            //   polylineList.push(polyline);
            // }
            // this.gaoDeMap.add(polylineList);
          } else {
            console.log("获取驾车数据失败：" + result);
            this.$message({
              showClose: true,
              message: "绘制路线失败",
              type: "warning",
            });
          }
        }
      );
    },
    getCargoNum(data) {
      let haulwayList = data.haulwayList;
      let load_num = 0;
      let unload_num = 0;
      for (let item of haulwayList) {
        if (item.action == 1) {
          load_num += item.real_cargo_num || item.cargo_num;
        } else if (item.action == -1) {
          unload_num += item.real_cargo_num || item.cargo_num;
        }
      }
      this.load_num = load_num;
      this.unload_num = unload_num;
    },
  },
  filters: {
    timestampToTime: function (timestamp) {
      if (timestamp != undefined) {
        var date = new Date(timestamp * 1000);
        var Y = date.getFullYear() + "-";
        var M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
        var D =
          (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
        var h =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
          ":";
        var m =
          (date.getMinutes() < 10
            ? "0" + date.getMinutes()
            : date.getMinutes()) + ":";
        var s =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        return Y + M + D + h + m;
      }
    },
  },
};
</script>
<style lang="scss">
.map_container {
  width: 100%;
  height: 100%;
  position: relative;
  #mapContainer {
    width: 100%;
    height: 100%;
  }
  .bm-view {
    width: 100%;
    height: 100%;
  }
  .map {
    width: 100%;
    height: 100%;
  }
  .order_content_wrap {
    position: absolute;
    right: 1%;
    top: 2%;
    height: 96%;
    overflow: hidden;
    overflow-y: auto;
    width: 300px;
    background: #fff;
  }
  .content_top {
    padding: 10px;
  }
  .content_header {
    height: 32px;
  }
  .content_title {
    font-size: 16px;
  }
  .content_tag {
    border: 1px solid #ddd;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    padding: 0 5px;
  }
  .order_driver {
    width: 100%;
    // height: 100px;
    background: #fce2c0;
    padding: 12px 10px;
    position: relative;
    .sanjiao {
      position: absolute;
      left: -2px;
      top: -3px;
      width: 26px;
      height: 26px;
    }
    .driver_avatar {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      object-fit: cover;
      margin-right: 4px;
    }
    .driver_name {
      font-size: 14px;
      color: #f54123;
    }
    .el-rate__icon {
      margin-right: 0;
      font-size: 14px;
    }
    .driver_phone,
    .driver_status {
      font-size: 12px;
      color: #333;
      text-align: left;
      margin-top: 4px;
      padding-left: 34px;
    }
    .driver_status {
    }
  }
  .txt_font {
    font-size: 12px;
    color: #333;
    text-align: left;
  }
  .content_bottom {
    padding: 10px;
  }
  // 步骤条
  .content_top .step_icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    line-height: 26px;
    border: none;
    font-size: 11px;
    text-align: center;
    color: #fff;
    background-color: #02a7f0;
  }
  .content_top .start_step_item,
  .content_top .last_step_item {
    margin-bottom: 6px;
  }
  .content_top .start_step_item .el-step__head.is-process {
    border-color: #02a7f0;
    color: #fff;
  }
  .content_top .start_step_item .el-step__head.is-wait {
    border-color: #02a7f0;
    color: #fff;
  }
  .content_top .last_step_item .el-step__head.is-process,
  .content_top .last_step_item .el-step__head.is-wait {
    border-color: #ff6543;
    color: #fff;
  }
  // 步骤条
  .content_bottom .step_icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    line-height: 26px;
    border: none;
    font-size: 11px;
    text-align: center;
    color: #fff;
    background-color: #c0c4cc;
  }
  .content_bottom .start_step_item .el-step__head.is-process {
    border-color: #c0c4cc;
    color: #fff;
  }

  .order_content_wrap .step_item .el-step__icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    line-height: 26px;
  }

  .order_content_wrap .el-step.is-vertical .el-step__head {
    width: 24px;
  }

  .order_content_wrap .el-step.is-vertical .el-step__line {
    width: 2px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    margin-right: auto !important;
  }

  .order_content_wrap .el-step__description {
    padding-right: 0;
  }
  .order_content_wrap .el-step.is-vertical .el-step__main {
    flex: 1 1;
  }
}
</style>
