import { axiosGet, axiosPost } from "@/api/request"
import apis from "@/api/apis"

// 获取订单数据
export function handleGetOrderDetail(data) {
    return axiosGet(apis.orderDetail, data);
}
// 添加到常用地址
export function handleAddCommonRoute(data) {
    return axiosPost(apis.addCommonRoute, data)
}